// CartItemsList.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, List, Typography, Row, Col, theme } from 'antd';
import { BankOutlined, EnvironmentOutlined } from '@ant-design/icons';
import CompanyInfo from './CompanyInfo';
import Meta from 'antd/es/card/Meta';

import { currencyFormatter } from '../const/formConstants';

const { Text, Paragraph, Title } = Typography;
const { useToken } = theme;

const CartItemsList = ({ cartItems }) => {
    const cart = useSelector(state => state.cart);
    const { token } = useToken();

    const renderItem = (cartItem) => (
        <List.Item key={cartItem.EntityNumber}>
            <CompanyInfo company={cartItem} showPrice></CompanyInfo>
        </List.Item>
    );

    return (
        <Card title="3 Review items">
            <List
                itemLayout='vertical'
                dataSource={cartItems}
                renderItem={renderItem}
                footer={
                    <>

                        <Row>
                            <Col span={3} offset={18}>
                                <Text strong style={{ color: token.colorSuccessActive }}>Order Total</Text>
                            </Col>
                            <Col span={3}>
                                <Text strong style={{ color: token.colorSuccessActive }}>{currencyFormatter.format(cart.orderTotal)}</Text>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    </>
                }
                bordered

            >
            </List>

        </Card >

    );
};

export default CartItemsList;