import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const paymentGatewayApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const baseQuery = fetchBaseQuery({
    baseUrl: paymentGatewayApiUrl, // read from environment variables
    prepareHeaders: (headers) => {
        // Add common header as required
        return headers
    }
})

export const paymentApiSlice = createApi({
    reducerPath: 'paymentApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({})
})
