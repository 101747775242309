import React from 'react';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Outlet } from 'react-router-dom';
import { ConfigProvider, theme, Layout, Menu, Row, Col } from 'antd';
import SiteHeader from './components/SiteHeader';
import About from './pages/about';
import Home from './pages/home';
import Searches from './pages/search';
import Login from './pages/Login';
import Register from './pages/register';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import CartPage from './pages/cart';
import Checkout from './pages/cart/checkout';
import ConfirmOrder from './pages/cart/confirm';
import Orders from './pages/user/orders';
import UserOrder from './pages/user/orderDetails';
import UserProfile from './pages/user/profile';
import UserManager from './pages/admin/users';
import OrderManager from './pages/admin/orders';
import './services/i18n';
import './styles/App.css';
import ProtectedRoute from './components/ProtectedRoute';

const { Header, Content, Footer } = Layout;


function App() {
  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1677ff',
          colorSuccess: '52c41a',
          colorWarning: 'faad14',
          colorError: 'ff4d4f',
        },
        // Card Token
        components: {
          Card: {
            headerBg: "#d7e6fa",
          },
        },

      }}
    >
      <Router>
        <Layout className="layout">
          <SiteHeader></SiteHeader>
          <Content style={{ padding: '50px' }}>
            <div className="site-layout-content">
              <Routes>
                <Route path="/" element={<Home />}
                  component={() => { global.window && (global.window.location.href = process.env.REACT_APP_WWW_URL) }}>
                </Route>
                <Route path="/about" element={<About />}>
                </Route>
                <Route path="/search" element={<Searches />}>
                </Route>
                <Route path="/login" element={<Login />}>
                </Route>
                <Route path="/register" element={<Register />}>
                </Route>
                <Route path="/forgotPassword" element={<ForgotPassword />}>
                </Route>
                <Route path="/resetPassword" element={<ResetPassword />}>
                </Route>
                <Route path="/cart" element={<CartPage cartItems={cartItems} />}>
                </Route>
                <Route path="/checkout" element={<Checkout />}>
                </Route>
                <Route path="/confirmOrder" element={<ConfirmOrder />}>
                </Route>
                <Route path="/user/orders" element={<Orders />}>
                </Route>
                <Route path="/user/order" element={<UserOrder />}>
                </Route>
                <Route path="/cart/confirm" element={<ConfirmOrder />}>
                </Route>
                <Route path="/user/profile" element={<UserProfile />}>
                </Route>
                <Route path="/admin/users" element={<UserManager />}>
                </Route>
                <Route path="/admin/orders" element={<OrderManager />}>
                </Route>
              </Routes>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Corporate Profiles Canada ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Router>
    </ConfigProvider>
  );
}

export default App;
