import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';

import { Card, Row, Col, Typography, Form, Input, Select, Button, Space } from 'antd';
import { EditOutlined, SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { formInputLayout, buttonItemLayout, provinceOptions, countryOptions } from '../const/formConstants';

import { selectCurrentUser, updateAddress } from '../features/userSlice';
import { useUpdateUserMutation } from '../features/api/cpcApiSlice';
const { Title, Text } = Typography;

const Address = ({ title, readonly }) => {
    const [isEdit, setIsEdit] = useState(false);

    const [updateUser, { isLoading }] = useUpdateUserMutation();
    const user = useSelector(selectCurrentUser);

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const isReadOnly = readonly;

    const onFinish = (data) => {
        console.log(data);

        var updatedUser = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phone,
            email: user.email,
            paymentProfileId: user.paymentProfileId,
            address: data.address,
            city: data.city,
            provinceCode: data.province,
            countryCode: data.country,
            postalZip: data.postalZip,
        };

        updateUser(updatedUser).unwrap()
            .then((data) => {
                dispatch(updateAddress(updatedUser));
            })
            .catch((err) => {
                console.log('update profile error:', err);
            })

        toggleEdit();
    };

    const toggleEdit = (data) => {
        setIsEdit(!isEdit);
        if (!isEdit) {
            form.setFieldsValue({
                address: user.address,
                city: user.city,
                province: user.provinceCode,
                country: user.countryCode,
                postalZip: user.postalZip
            });
        }
    }

    return (
        <>
            <Card
                title={title}
            >
                {!isEdit &&
                    <Form  {...formInputLayout} layout="horizontal" form={form} name="addressForm" onFinish={toggleEdit}>
                        <Row justify='center'>
                            <Col span={12}>
                                <Form.Item
                                    label={t('formLabel.addr1Input')}
                                    name="address"
                                >
                                    {user.address}
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.cityInput')}
                                    name="city"
                                >
                                    {user.city}
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.provinceInput')}
                                    name="province"
                                >
                                    {user.provinceCode}
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.countryInput')}
                                    name="country"
                                >
                                    {user.countryCode}
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.postalCodeInput')}
                                    name="postalZip"
                                >
                                    {user.postalZip}
                                </Form.Item>

                                {!isReadOnly &&
                                    <Form.Item {...buttonItemLayout}>
                                        <Button type="primary" htmlType="submit">
                                            <EditOutlined /> {t('formLabel.editButton')}
                                        </Button>
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                    </Form>
                }
                {isEdit &&
                    <Form {...formInputLayout} layout="horizontal" form={form} onFinish={onFinish}  >
                        <Row justify='center'>
                            <Col span={12}>
                                <Form.Item
                                    label={t('formLabel.addr1Input')}
                                    name="address"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.cityInput')}
                                    name="city"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.provinceInput')}
                                    name="province"
                                >
                                    <Select options={provinceOptions} />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.countryInput')}
                                    name="country"
                                >
                                    <Select options={countryOptions} />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.postalCodeInput')}
                                    name="postalZip"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item {...buttonItemLayout}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            <SaveOutlined /> {t('formLabel.saveButton')}
                                        </Button>

                                        <Button type="defualt" onClick={toggleEdit}>
                                            <CloseCircleOutlined /> {t('formLabel.cancelButton')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
            </Card>
        </>
    );


}

export default Address;