import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { PaymentProfielResponseCode } from '../const/BamboraConstant';

import { Card, Row, Col, Typography, Form, Input, Button, Space, Switch, Spin, DatePicker, InputNumber } from 'antd';
import { EditOutlined, SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { formInputLayout, buttonItemLayout } from '../const/formConstants';

import { selectCurrentUser, addCreditCard, updateCreditCard, removeCreditCard, setPaymentPrrofile } from '../features/userSlice';
import { useCreateProfileMutation, useGetProfileCardsQuery, useAddProfileCardMutation, useUpdateProfileCardMutation } from '../features/api/cardPaymentApiSlice';
import { useUpdateUserMutation } from '../features/api/cpcApiSlice';

import moment from 'moment';

const { Title, Text } = Typography;

const CreditCard = (props) => {

    const user = useSelector(selectCurrentUser);

    const cardId = props.cardId;
    const [isNew, setIsNew] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [profileId, setProfileId] = useState(user.paymentProfileId);
    const [creditCard, setCreditCard] = useState({});

    const { data: creditCards, error: retrievingError, isLoading: isRetrieving } = useGetProfileCardsQuery({ "paymentProfileId": profileId });
    const [createProfile, { isLoading: isCreating, data: createProfileResponse, error: createCardError }] = useCreateProfileMutation();
    const [addCard, { isLoading: isAdding, data: addCardResponse, error: addCardError }] = useAddProfileCardMutation();
    const [updateCard, { isLoading: isUpdating, data: updateCardResponse, error: updateCardError }] = useUpdateProfileCardMutation();
    const [updateUser, { isLoading }] = useUpdateUserMutation();

    const dispatch = useDispatch();

    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        // setProfileId(user.paymentProfileId);
        if (creditCards) {
            // set the expDate in cards.
            if (creditCards.code === PaymentProfielResponseCode.Successful) {
                const cc = {
                    ...creditCards.card[0],
                    expDate: moment({
                        year: creditCards.card[0].expiry_year,
                        month: creditCards.card[0].expiry_month,
                        day: 1
                    })
                };
                setCreditCard(cc);
                setIsNew(false);
            }
        }
        // if mark as new or no saved card, show neww card
        else if (props.isNew || !user.paymentProfileId) {
            setIsNew(true);
        }
    }, []);

    const onFinish = (data) => {
        // create card object from form
        const cc = {
            cardId: data.cardId,
            name: data.cardName,
            number: data.cardNumber,
            expiry_month: data.expDate.format('MM'),
            expiry_year: data.expDate.format('YY'),
            cvd: data.cvd
        };

        // if has a profoile,  update card to profile and save to dateabase
        // if mark to save, create profile from card and save profile to dateabase
        // other wise, save token in local storage
        if (data.cardId) {
            updateCard({ card: cc, paymentProfileId: user.paymentProfileId }).unwrap()
                .then((data) => {
                    console.log(data);
                    if (data.code == PaymentProfielResponseCode.Successful) {
                        toggleEdit();
                    }
                })
        }
        else {
            // dispatch(addCreditCard(data));
            if (!user.paymentProfileId) {
                // new profile with card
                createProfile({ ...cc }).unwrap()
                    .then((data) => {
                        console.log("Created CC Profile: ", data);
                        if (data.code == PaymentProfielResponseCode.Successful) {
                            // set  profile id
                            var updatedUser = { ...user };
                            updatedUser.paymentProfileId = data.customer_code;

                            // update user to database
                            updateUser(updatedUser).unwrap()
                                .then((data) => {
                                    dispatch(setPaymentPrrofile(data));
                                    setCreditCard(cc);
                                    setIsNew(false);
                                })
                                .catch((err) => {
                                    console.log('update user error:', err);
                                })

                            // toggle edit
                            toggleEdit();

                        }
                    })
            }
            else {
                // has profile but no card, invalid scenario
                addCard({ card: cc, paymentProfileId: user.paymentProfileId }).unwrap()
                    .then((data) => {
                        console.log(data);
                        if (data.code == PaymentProfielResponseCode.Successful) {
                            toggleEdit();
                        }
                    })

            }


        }


    };


    const toggleEdit = (data) => {
        setIsEdit(!isEdit);
        if (!isEdit) {
            form.setFieldsValue({
                cardId: creditCard.card_id,
                cardName: creditCard.name,
                cardNumber: creditCard.number,
                /*
                expDate: moment({ year: creditCard.expiry_year, 
                                  month: creditCard.expiry_month, 
                                  day: 1 }),
                                  */
                expDate: moment(creditCard.expiry_year + '-' + creditCard.expiry_month + '-01', 'YYYY-MM-DD'),
                // expMonth: creditCard.expiry_month,
                // expYear: creditCard.expiry_year,
            });
        }
    }

    const toggleSaveCard = (checked, e) => {
        // if checked, save card to profile 
        console.log(checked, e);
    }

    if (isRetrieving || isAdding || isUpdating) {
        return () => <Spin tip="Loading..." size="large" />
    }

    return (
        <>
            <Card title={props.title}>
                {
                    !isEdit && !isNew &&
                    <Form  {...formInputLayout} layout="horizontal" form={form} name="creditcardForm" onFinish={toggleEdit}>
                        < Row justify='center' >
                            <Col span={24}>
                                <Form.Item
                                    label={t('formLabel.nameInput')}
                                    name="cardName"
                                >
                                    {creditCard.name}
                                </Form.Item>
                                <Form.Item
                                    label={t('formLabel.cardNumberInput')}
                                    name="cardNumber"
                                >
                                    {creditCard.number}
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.expiryMonthInput')}
                                    name="expDate"
                                >
                                    {creditCard.expiry_month}/{creditCard.expiry_year}
                                </Form.Item>

                                <Form.Item {...buttonItemLayout}>
                                    <Button type="primary" htmlType="submit">
                                        <EditOutlined /> {t('formLabel.editButton')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row >
                    </Form >
                }
                {
                    (isEdit || isNew) &&
                    <Form {...formInputLayout} layout="horizontal" form={form} name="creditcardForm" onFinish={onFinish}  >
                        <Row justify='center'>
                            <Col span={12}>
                                <Form.Item
                                    label={t('formLabel.nameInput')}
                                    name="cardName"
                                    rules={[
                                        { required: true, message: t('formErrorMsg.cardHolderNameRequired') },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label={t('formLabel.cardNumberInput')}
                                    name="cardNumber"
                                    rules={[
                                        { required: true, message: t('formErrorMsg.cardNumberRequired') },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.expiryDate')}
                                    name="expDate"
                                    rules={[
                                        { required: true, message: t('formErrorMsg.cardExpiryDateRequired') },
                                    ]}
                                >
                                    <DatePicker picker="month" format={"MM/YY"} />
                                </Form.Item>

                                <Form.Item
                                    label={t('formLabel.cardCvdInput')}
                                    name="cvd"
                                    rules={[
                                        { required: true, message: t('formErrorMsg.cardCvdRequired') },
                                    ]}
                                >
                                    <InputNumber max={999} precision={0} />
                                </Form.Item>

                                {
                                    isNew &&
                                    <Form.Item
                                        label={t('formLabel.saveCardToProfile')}
                                        name="saveCardSwitch"
                                    >
                                        <Switch onChange={toggleSaveCard} />
                                    </Form.Item>
                                }

                                <Form.Item {...buttonItemLayout}>
                                    <Space>
                                        <Button type="primary" htmlType="submit">
                                            <SaveOutlined /> {t('formLabel.saveButton')}
                                        </Button>

                                        <Button type="defualt" onClick={toggleEdit}>
                                            <CloseCircleOutlined /> {t('formLabel.cancelButton')}
                                        </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label={t('formLabel.cardIdInput')}
                            name="cardId" style={{ "display": "none" }}>
                            <Input />
                        </Form.Item>

                    </Form>
                }
            </Card >
        </>
    );

}

export default CreditCard;