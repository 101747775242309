import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Typography, Row, Col, Divider, theme } from 'antd';
import { SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';

import { currencyFormatter } from '../const/formConstants';

const { Title, Text } = Typography;
const { useToken } = theme;

const OrderSummary = ({ order }) => {
    const dispatch = useDispatch();
    const { token } = useToken();
    // const cart = useSelector(state => state.cart);

    // Commponent layout
    return (
        <>
            <Title level={3}>Order Summary</Title>
            <Row>
                <Col span={10} offset={6}>
                    <Text>Subtotal ({order.count} report{order.count > 1 ? "s" : ""})</Text>
                </Col>
                <Col span={6}>
                    <Text strong>{currencyFormatter.format(order.subTotal)}</Text>
                </Col>
            </Row>
            <Divider></Divider>
            <Row>
                <Col span={10} offset={6}>
                    <Text>Total Before Tax</Text>
                </Col>
                <Col span={6}>
                    <Text strong>{currencyFormatter.format(order.subTotal)}</Text>
                </Col>
            </Row>
            <Row>
                <Col span={10} offset={6}>
                    <Text>Tax(HST)</Text>
                </Col>
                <Col span={6}>
                    <Text strong>{currencyFormatter.format(order.tax)}</Text>
                </Col>
            </Row>
            <Divider></Divider>
            <Row>
                <Col span={10} offset={6}>
                    <Title level={5} style={{ color: token.colorSuccessActive }}>Order Total</Title>
                </Col>
                <Col span={6}>
                    <Title level={5} style={{ color: token.colorSuccessActive }}>{currencyFormatter.format(order.orderTotal)}</Title>
                </Col>
            </Row>

            <Divider></Divider>
            <Text><Link>How are tax calculate?</Link></Text>
        </>
    );
};

export default OrderSummary;
