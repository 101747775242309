import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Card, Form, Input, Button, Radio, Select, message, Spin, DatePicker, Divider } from 'antd';
import moment from 'moment';
import { selectCurrentUser } from '../features/userSlice';
// import { useGetProfileCardsQuery } from '../features/api/paymentApiSlice';
import { useGetProfileCardsQuery } from '../features/api/cardPaymentApiSlice';
import { setPaymentMethod } from '../features/cartSlice';

const { Option } = Select;

const PaymentMethod = forwardRef(({ profileId, onPaymentMethodSelected }, ref) => {
    const [form] = Form.useForm();
    const [paymentMethod, setPaymentMethod] = useState('card');
    const { data: storedCards, isLoading: loadingCards } = useGetProfileCardsQuery({ "paymentProfileId": profileId });

    useEffect(() => {
        form.resetFields();

        if (storedCards) {
            //            form.setFieldsValue({ storedCardId: storedCards[0].id });
            console.log('storedCards:', storedCards);
        }
        else {
            console.log('storedCards: empty');
        }
    }, [paymentMethod, form]);

    // submit to be triggered by parent
    useImperativeHandle(ref, () => ({
        submitForm: async () => {
            return form
                .validateFields() // Validates the form fields
                .then((values) => {
                    return handleSubmit(values);
                    // return true; // Resolves the promise if the form is valid
                })
                .catch((errorInfo) => {
                    console.error('Form validation failed:', errorInfo);
                    return false; // Rejects the promise if there are validation errors
                });
        },
    }));

    const handleSubmit = async (values) => {
        if (paymentMethod === 'card') {
            // trigger parent payment method select on submit
            const result = await onPaymentMethodSelected({
                payment_method: 'card',
                complete: true,
                cardName: values.nameonCard,
                cardNumber: values.cardNumber,
                expMonth: values.expiryDate.format('MM'),
                expYear: values.expiryDate.format('YY'),
                cvd: values.cvv
            });

            return result;
        } else {
            // trigger parent payment method select on submit
            const result = await onPaymentMethodSelected({
                payment_method: 'payment_profile',
                complete: true,
                customerCode: storedCards.customer_code,
                cardId: values.storedCardId,
            });

            return result;
        }
    };

    return (
        <Card title="2 Payment Method">
            <div>
                <Radio.Group onChange={(e) => setPaymentMethod(e.target.value)} value={paymentMethod}>
                    <Radio value="card">One-Time Payment</Radio>
                    <Radio value="payment_profile" disabled={!storedCards || storedCards.length === 0}>
                        Use Stored Card
                    </Radio>
                </Radio.Group>

                <Divider></Divider>

                {paymentMethod === 'card' ? (
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            label="Name on Card"
                            name="nameonCard"
                            rules={[{ required: true, message: 'Please input name on card' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Card Number"
                            name="cardNumber"
                            rules={[{ required: true, message: 'Please input your card number!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Expiry Date"
                            name="expiryDate"
                            rules={[{ required: true, message: 'Please select the expiry date!' }]}
                        >
                            <DatePicker picker="month" format="MM/YY" placeholder="MM/YY" />
                        </Form.Item>
                        <Form.Item
                            label="CVV"
                            name="cvv"
                            rules={[{ required: true, message: 'Please input the CVV!' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                ) : loadingCards ? (
                    <Spin />
                ) : (
                    <Form form={form} onFinish={handleSubmit}>
                        <Form.Item
                            label="Stored Cards"
                            name="storedCardId"
                            rules={[{ required: true, message: 'Please select a stored card!' }]}
                        >
                            <Select placeholder="Select a stored card">
                                {storedCards.card.map((card) => (
                                    <Option key={card.card_id} value={card.card_id}>
                                        [{card.name}] {card.number} - {card.expiry_month}/{card.expiry_year}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </Card>
    );
});

export default PaymentMethod;