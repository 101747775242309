import React from 'react';
import { Row, Col, Card, Typography, List, Space } from 'antd';

import { useSelector } from 'react-redux';

import UserProfile from '../../components/UserProfile';
import ChangePasword from '../../components/ChangePassword';
import Address from '../../components/Address';
import CreditCard from '../../components/CreditCard';

const { Title, Text } = Typography;

const ProfilePage = () => {
    const creditCards = useSelector(state => state.user.creditCards);

    return (
        <>
            <Title level={2}>My Profile</Title>
            <Card>
                <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
                    <UserProfile title="Profile"></UserProfile>
                    <ChangePasword title="Change Password"></ChangePasword>
                    <Address title="Address"></Address>
                    <CreditCard title="Credit Cards" isNew={false}></CreditCard>
                </Space>
            </Card>
        </>
    );
};

export default ProfilePage;