import { paymentApiSlice } from "./paymentApiSlice";

const apiKey = process.env.REACT_APP_BAMBORA_KEY;
const paymentApiKey = process.env.REACT_APP_BAMBORA_PAYMENT_KEY;

export const cardPaymentApiSlice = paymentApiSlice.injectEndpoints({
    endpoints: (builder) => ({
        postCardPayment: builder.mutation({
            query: (data) => ({
                url: '/payments',
                method: 'POST',
                headers: {
                    'Authorization': `Passcode ${paymentApiKey}`,
                },
                body: {
                    amount: data.amount,
                    payment_method: "card",
                    "card": {
                        "name": data.cardName,
                        "number": data.cardNumber,
                        "expiry_month": data.expMonth,
                        "expiry_year": data.expYear,
                        "cvd": data.cvd
                    }
                }
            })
        }),
        postProfileCardPayment: builder.mutation({
            query: (data) => ({
                url: '/payments',
                method: 'POST',
                headers: {
                    'Authorization': `Passcode ${paymentApiKey}`,
                },
                body: {
                    "order_number": data.orderNumber,
                    "amount": data.amount,
                    "payment_method": "payment_profile",
                    "payment_profile": {
                        "complete": true,
                        "customer_code": data.customerCode,
                        "card_id": data.cardId
                    }
                }
            })
        }),
        createProfile: builder.mutation({
            query: (data) => ({
                url: '/profiles',
                method: 'POST',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
                body: {
                    "card": {
                        "name": data.name,
                        "number": data.number,
                        "expiry_month": data.expiry_month,
                        "expiry_year": data.expiry_year,
                        "cvd": data.cvd
                    },
                    "billing": {},
                    "validate": true
                }
            })
        }),
        addProfileCard: builder.mutation({
            query: (data) => ({
                url: '/profiles/' + data.paymentProfileId + '/cards',
                method: 'POST',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
                body: {
                    "card": {
                        "name": data.cardName,
                        "number": data.cardNumber,
                        "expiry_month": data.expMonth,
                        "expiry_year": data.expYear,
                        "cvd": data.cvd
                    }
                }
            })
        }),
        // Update card only allow 1 card on profile
        updateProfileCard: builder.mutation({

            query: (data) => ({
                url: '/profiles/' + data.paymentProfileId + '/cards/1',
                method: 'PUT',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
                headers: {
                    'Authorization': `Passcode ${process.env.REACT_APP_BAMBORA_KEY}`,
                    "Content-Type": "application/json"
                },
                body: {
                    "card": { ...data.card },
                    validate: true
                }
            })
        }),
        removeProfileCard: builder.mutation({
            query: (data) => ({
                url: '/profiles/' + data.paymentProfileId + '/cards/' + data.cardId,
                method: 'DELETE',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
            })
        }),
        getProfileCards: builder.query({
            query: (data) => ({
                url: '/profiles/' + data.paymentProfileId + '/cards',
                method: 'GET',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
            })
        }),
        getProfileCard: builder.query({
            query: (data) => ({
                url: '/profiles/' + data.paymentProfileId + '/cards/1',
                method: 'GET',
                headers: {
                    'Authorization': `Passcode ${apiKey}`,
                },
            })
        }),
    })
})

export const {
    usePostCardPaymentMutation,
    usePostProfileCardPaymentMutation,
    useCreateProfileMutation,
    useAddProfileCardMutation,
    useRemoveProfileCardMutation,
    useGetProfileCardsQuery,
    useGetProfileCardQuery,
    useUpdateProfileCardMutation,
} = cardPaymentApiSlice