import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography, Row, Col, Divider, Space, message, Alert } from 'antd';
import { SafetyCertificateOutlined } from '@ant-design/icons';

import { usePlaceOrderMutation } from '../../features/api/orderApiSlice';
import { useGetCartMutation } from '../../features/api/cartApiSlice';
import { useClearCartMutation } from '../../features/api/cartApiSlice';
import { usePostCardPaymentMutation, usePostProfileCardPaymentMutation } from '../../features/api/cardPaymentApiSlice';
import { placeOrder, setLastOrder } from '../../features/orderSlice';
import { initCart, setCart } from '../../features/cartSlice';
import { selectCurrentUser } from '../../features/userSlice';


import OrderSummary from '../../components/OrderSummary';
import CreditCardList from '../../components/CreditCardList';
import PaymentMethod from '../../components/PaymentMethod';
import CartItemsList from '../../components/CartItemsList';
import Address from '../../components/Address';
import { set } from 'react-hook-form';
const { Title, Text } = Typography;



const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(selectCurrentUser);
  const cart = useSelector(state => state.cart);
  const creditCards = useSelector(state => state.user.creditCards);
  const [profileId, setProfileId] = useState(user.paymentProfileId);
  const [order, setOrder] = useState({})
  const [paymentMethod, setPaymentMethod] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [paymentError, setPaymentError] = useState(false);
  const [paymentErrorMsg, setPaymentErrorMsg] = useState('');

  const [placeOrder] = usePlaceOrderMutation();
  const [getCart] = useGetCartMutation();
  const [clearCart] = useClearCartMutation();
  const [postCardPayment, { isLoading: isCardPaymentLoading, isSuccess: isCardPaymentSuccess, data: cardPaymentResponse, error: cardPaymentError }] = usePostCardPaymentMutation();
  const [postProfileCardPayment, { isLoading: isProfileCardPaymentLoading, isSuccess: isProfileCardPaymentSuccess, data: profileCardPaymentResponse, error: profileCardPaymentError }] = usePostProfileCardPaymentMutation();
  const [selectedCard, setSelectedCard] = useState();

  // reference to payment method component submit form
  const paymentMethodRef = useRef();

  const handleCardSelect = (cardId) => {
    setSelectedCard(cardId);
  };

  const handlePaymentMethodSelected = async (paymentData) => {
    console.log('Selected Payment Method:', paymentData);
    // Handle the payment processing here
    paymentData.amount = cart.orderTotal;
    setPaymentMethod(paymentData);

    if (paymentData.payment_method === 'card') {

      try {
        // post card payment to payment gateway
        const response = await (postCardPayment(paymentData));

        // check response 
        if (response) {
          console.log('postCardPayment response:', response);

          // save transaction details
          setTransactionData(response);

          // response has error
          if (response.error) {
            console.log('postCardPayment error:', response);

            setPaymentErrorMsg(response.error.data.message);
            setPaymentError(true);
            return false;
          }

          // no error
          setPaymentError(false);
          return true;
        }
      } catch (error) {
        console.log('postCardPayment error:', error);
        setPaymentError(true);
        return false;
      }


    }
    else if (paymentData.payment_method === 'payment_profile') {

      try {
        // post card payment to payment gateway
        const response = await (postProfileCardPayment(paymentData));

        // check response 
        if (response) {
          console.log('postProfileCardPayment response:', response);

          // save transaction details
          setTransactionData(response);

          // response has error
          if (response.error) {
            console.log('postProfileCardPayment error:', response);

            setPaymentErrorMsg(response.error.data.message);
            setPaymentError(true);
            return false;
          }

          // no error
          setPaymentError(false);
          return true;
        }
      } catch (error) {
        console.log('postProfileCardPayment error:', error);
        setPaymentError(true);
        return false;
      }

    }
  };

  const handleCheckOut = async (data) => {
    // Create Order Object and Copy cart data
    var tempOrder = { ...cart };
    // add user data to order
    tempOrder.user = user;
    // setOrder(tempOrder);

    // clear error messages
    setPaymentError(false);
    setPaymentErrorMsg('');

    try {
      // add credit card data to user.
      if (paymentMethodRef.current) {
        // create order in database and retrieve order number

        // try post payment
        const isPaymentValid = await paymentMethodRef.current.submitForm();

        // update order status

        // clear cart

        console.log('isPaymentValid:', isPaymentValid);
        console.log('paymentError:', paymentError);

        if (!isPaymentValid) {
          message.error('Error processing payment, please review errors', 5);
          return;
        }

        if (isPaymentValid && !paymentError) {
          // Place Order
          placeOrder(tempOrder).unwrap()
            .then((response) => {
              dispatch(setLastOrder(response));
              // Clear Cart
              clearCart(user).unwrap()
                .then((response) => {
                  // initialize cart
                  getCart(user).unwrap()
                    .then((getCartResponse) => {
                      dispatch(initCart);
                      dispatch(setCart(getCartResponse));
                    });
                });


              // Navigate to confirm order
              navigate("/confirmOrder");
            })
            .catch((error) => {
              console.log('place Order Error:', error);
              // TODO: Show error
            })
            ;
        }
      }

    } catch (error) {
      console.log('Payment Method Error:', error);
      // TODO: Show error
    }
  }


  return (
    <>
      <Title level={2}>Checkout ({cart.count} item{cart.count > 1 ? "s" : ""})</Title>
      <Row gutter={[12, 12]}>
        <Col span={16}>
          <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
            <Address title="1 Billing Address" />

            {paymentErrorMsg &&
              <Row justify='center'>
                <Col span={24}>
                  <Alert message={paymentErrorMsg} type="error" banner />
                  <br />
                </Col>
              </Row>
            }
            <PaymentMethod ref={paymentMethodRef} profileId={profileId} onPaymentMethodSelected={handlePaymentMethodSelected} />

            <CartItemsList cartItems={cart.items} />

            <Card>
              <Button type="primary" style={{ "width": "100%" }} onClick={handleCheckOut}>
                <SafetyCertificateOutlined />{t('formLabel.placeOrderButton')}
              </Button>
              <Text>By placing your order, you agree to Corporate Profiles Canada's <Link>privacy notice</Link> and <Link>condition of use</Link></Text>
            </Card>
          </Space>
        </Col>
        <Col span={8}>
          <Card>
            <Button type="primary" style={{ "width": "100%" }} onClick={handleCheckOut}>
              <SafetyCertificateOutlined />{t('formLabel.placeOrderButton')}
            </Button>
            <Text>By placing your order, you agree to Corporate Profiles Canada's <Link>privacy notice</Link> and <Link>condition of use</Link></Text>
            <Divider></Divider>
            <OrderSummary order={cart} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Checkout;
