import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Row, Col, Typography, Form, Input, Select, Button, Space, theme } from 'antd';

const { Title, Text } = Typography;
const { useToken } = theme;

const ConfirmOrder = () => {
  const order = useSelector(state => state.orders);
  const { token } = useToken();

  const getOrderNumber = () => {
    return order.lastOrder.orderNumber;
  }

  return (
    <div>
      <Card
        title={"Confirmed Order: [" + getOrderNumber() + "]"}
        headStyle={{ backgroundColor: token.colorSuccess, fontSize: '24px' }}
        bodyStyle={{ backgroundColor: token.colorSuccessBg, color: token.colorSuccessTextActive }}
      >
        <Card.Meta
          description="Thank you for your order. You will recieve an email once the reports are ready for download">

        </Card.Meta>


      </Card>
    </div >
  );
};

export default ConfirmOrder;