import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import {
  persistReducer, persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import thunk from 'redux-thunk';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authReducer from './features/authSlice';
import cartReducer from './features/cartSlice';
import userReducer from './features/userSlice';
import orderReducer from './features/orderSlice';
import userSettingsReducer from './features/userSettingsSlice'; // Update the import

import { apiSlice } from './features/api/apiSlice';
import { paymentApiSlice } from './features/api/paymentApiSlice';

// Configure where data is presisted
// Default stores all in local store
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

// user information stores within Session only.
const userPersistConfig = {
  key: 'user',
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
}

// reducers to be included in store
const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  orders: orderReducer,
  user: persistReducer(userPersistConfig, userReducer),
  userSettings: userSettingsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  [paymentApiSlice.reducerPath]: paymentApiSlice.reducer
});

// reducers to be included in store
const persistedReducer = persistReducer(persistConfig, rootReducer)

// Configure Redux  Store
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // middleware: [thunk]
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Redux persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, paymentApiSlice.middleware),
});

// Exports
export const persistor = persistStore(store);