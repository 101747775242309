import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Alert } from 'antd';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formInputLayout } from '../const/formConstants';
import { useDispatch } from 'react-redux';

// local login - store user information in userSlice
import { setCredentials, setCreditCards } from '../features/userSlice';
import { initCart, setCart } from '../features/cartSlice';

// login integration
// import authService from '../services/AuthService';
import { useLoginMutation } from '../features/api/authApiSlice';
import { useGetCartMutation } from '../features/api/cartApiSlice';

const { Title, Text } = Typography;

const LoginForm = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState('');

  const [login, { isLoading }] = useLoginMutation();
  const [getCart, { isCartLoading }] = useGetCartMutation();

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);


  const [email, setEmail] = useState("");
  const passwordValue = Form.useWatch('password', form);

  const [rememberMe, setRememberMe] = useState(false);
  const { from } = location.state || { from: { pathname: '/' } }; // Default to home if no redirect path is provided

  const onSubmitEmail = (data) => {
    // Send email for verification, update UI accordingly
    // For simplicity, just move to the next step
    // Toto: Add server logic
    setEmail(data.email);
    setStep(2);
  };

  const onSubmitPassword = (data) => {

    // Handle login with email and password
    const password = passwordValue;
    console.log(email, password, rememberMe);

    // login user and populate user profile information
    login({ email, password, rememberMe })
      .unwrap()
      .then((userData) => {
        dispatch(setCredentials({ ...userData, rememberMe }));
        dispatch(initCart());

        // load shopping cart
        getCart({ id: userData.user.id }).unwrap()
          .then((cart) => {
            dispatch(setCart(cart));
          })

        // go to previous route before login
        navigate(-1);
      })
      .catch((err) => {
        if (!err?.status) {
          // isLoading: true until timeout occurs            
          setErrMsg(t('formErrorMsg.serverError'));
        } else if (err.status === 400) {
          // Input error
          setErrMsg(t('formErrorMsg.invalidLogin'));
        } else if (err.status === 401) {
          // 'Unauthorized'
          setErrMsg(t('formErrorMsg.invalidLogin'));
        } else {
          // 'Login Failed'
          setErrMsg(t('formErrorMsg.loginFailed'));
        }
      });
  };

  const handleCancel = () => {
    navigate(-1);
  }

  const handleBack = () => {
    setStep(1);
  };


  return (
    <div>
      <Row justify='center'>
        <Col span={12}>
          <Title level={2}>{step === 1 ? t('loginForm.title') : t('loginForm.title')}</Title>
        </Col>
      </Row>
      {errMsg &&
        <Row justify='center'>
          <Col span={12}>
            <Alert message={errMsg} type="error" />
            <br />
          </Col>
        </Row>
      }

      {step === 1 && (
        <>
          <Form form={form} name="emailform" onFinish={onSubmitEmail} {...formInputLayout}>
            <Row justify='center'>
              <Col span={12}>
                <Form.Item
                  label={t('formLabel.emailInput')}
                  name="email"
                  rules={[
                    { required: true, message: t('formErrorMsg.emailRequired') },
                  ]}
                >
                  <Input type="text" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center'>
              <Col span={1}>
                <NavLink to='/Register'>
                  <Button type="link">
                    {t('loginForm.registerLinkLabel')}
                  </Button>
                </NavLink>
              </Col>
              <Col span={1}>
                <NavLink to='/ResetPassword'>
                  <Button type="link">
                    {t('formLabel.forgottPasswordLinkLabel')}
                  </Button>
                </NavLink>
              </Col>
              <Col span={1} offset={9}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t('formLabel.nextButton')}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item>
                  <Button type="default" htmlType="button" onClick={(handleCancel)}>
                    {t('formLabel.cancelButton')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>

      )}

      {step === 2 && (
        <>
          <Form form={form} name="passwordform" onFinish={onSubmitPassword} {...formInputLayout}>
            <Row justify='center'>
              <Col span={12}>
                <Form.Item
                  label={t('formLabel.passwordInput')}
                  name="password"
                  rules={[{ required: true, message: t('formErrorMsg.passwordRequired') }]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            </Row>
            <Row justify='center'>
              <Col span={1}
                style={{ textAlign: 'left', }}>
                <NavLink to='/ResetPassword'>
                  <Button type="link">
                    {t('formLabel.forgottPasswordLinkLabel')}
                  </Button>
                </NavLink>
              </Col>
              <Col span={1}
                offset={9}
                style={{ textAlign: 'right', }}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {t('formLabel.loginButton')}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item>
                  <Button type="default" htmlType="button" onClick={(handleBack)}>
                    {t('formLabel.backButton')}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={1}>
                <Form.Item>
                  <Button type="default" htmlType="button" onClick={(handleCancel)}>
                    {t('formLabel.cancelButton')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default LoginForm;
